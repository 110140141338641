const STANLEY_HAYFORD_PORTFOLIO_THEME_KEY = 'STANLEY_HAYFORD_PORTFOLIO_THEME_KEY';
const EMAIL_JS_SERVICE_ID = 'service_jtkewl3';
const EMAIL_JS_PUBLIC_KEY = 'HE7xSR_GHuebHqHzN';
const EMAIL_JS_TEMPLATE_ID = 'template_xtb9cm5';

export {
    STANLEY_HAYFORD_PORTFOLIO_THEME_KEY,
    EMAIL_JS_SERVICE_ID,
    EMAIL_JS_TEMPLATE_ID,
    EMAIL_JS_PUBLIC_KEY
};